import React, {useEffect, useState} from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import theme from "theme";
import {FeatureRow} from "../business-entity-tabs/business-entity-tabs.component";
import {FeatureType} from "../../../types/features";
import FeatureFormula from "../../shared/feature-display/feature-formula.component";
import FeatureInfo from "../../shared/feature-display/feature-info.component";

interface BusinessEntityDrawerProps {
    onClose: () => void;
    features: FeatureRow[];
    selectedFeature: FeatureRow | null;
}

const BusinessEntityDrawer: React.FC<BusinessEntityDrawerProps> = ({onClose, features, selectedFeature}) => {

    /**
     * For some reason passing Boolean(selectedFeature) causes a re-renderinng of the Drawer
     * Causing the animation to fail:
     * https://github.com/mui/material-ui/issues/10587#issuecomment-1557705860
     */
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => setIsOpen(Boolean(selectedFeature)), [selectedFeature]);

    return (
        <Drawer
            open={isOpen}
            anchor="right"
            onClose={onClose}
            BackdropProps={{invisible: true}}
            sx={{
                border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                boxShadow: '0px 4px 20px 0px rgba(97, 97, 97, 0.13)',
            }}
        >
            <Box className="flex-box-col-between"
                 sx={{boxSizing: "border-box", width: "525px", height: "100%", padding: "32px 40px 40px 40px"}}>
                {selectedFeature && (
                    <Box>
                        <Typography variant="subtitle2" sx={{
                            fontWeight: 600,
                            color: theme.palette.customColor.black,
                            marginBottom: "16px"
                        }}>
                            Feature info
                        </Typography>

                        <Box sx={{
                            borderRadius: "16px",
                            border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                            padding: "16px"
                        }}>
                            {selectedFeature.featureType === FeatureType.formula ? (
                                <FeatureFormula features={features} formulaFeature={selectedFeature}/>
                            ) : (
                                <FeatureInfo assetFeature={selectedFeature}/>
                            )}
                        </Box>
                    </Box>
                )}

                <Box>
                    <Divider sx={{width: "100%", backgroundColor: theme.palette.customColor.lightGrey1}}/>
                </Box>
            </Box>
        </Drawer>
    );
};

export default BusinessEntityDrawer;