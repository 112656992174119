import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "theme";
import {Divider} from "@mui/material";
import "./feature-info.component.scss";
import {FeatureRow} from "../../business-entity-component/business-entity-tabs/business-entity-tabs.component";
import FeatureInfo from "./feature-info.component";

interface FeatureFormulaFormulaProps {
    features: FeatureRow[];
    formulaFeature?: FeatureRow;
}

const FeatureFormula: React.FC<FeatureFormulaFormulaProps> = ({
                                                                         features,
                                                                         formulaFeature,
                                                                     }) => {
    const [featuresFormula, setFeaturesFormula] = useState<FeatureRow[]>([]);

    const featureTypeIcons: { [key: string]: string } = {
        formula: "ƒ",
        formulaSQL: "tag",
    };

    const calculateFormulaSQL = () => {
        if (featuresFormula.length > 0) {
            return featuresFormula
                .map((feature) => feature.featureName)
                .join(" + ");
        }
        return "";
    };

    useEffect(() => {
        const derivedNames = formulaFeature?.featureDerivedName || [];
        if (derivedNames.length > 0) {
            const featuresFormula = features.filter(feature =>
                derivedNames.includes(feature.featureName)
            );
            setFeaturesFormula(featuresFormula);
        } else {
            setFeaturesFormula([]);
        }
    }, [features, formulaFeature]);

    return (
        <Box className="flex-box-col-start" sx={{width: "100%", gap: "16px"}}>
            <Box sx={{width: "100%"}}>
                <Typography
                    className="flex-box-align-center"
                    variant="body1"
                    sx={{
                        color: theme.palette.customColor.grey,
                        lineHeight: "20px",
                        textTransform: "capitalize",
                        marginBottom: "8px"
                    }}
                >
                    {formulaFeature?.featureType}
                </Typography>
                <Box className="flex-box-align-start" sx={{width: "100%"}}>
                <span
                    className="material-symbols-outlined"
                    style={{fontSize: "18px", color: theme.palette.customColor.purple, marginRight: "10px"}}
                >
                        {formulaFeature?.featureType && featureTypeIcons[formulaFeature.featureType]}
                    </span>
                    <Typography variant="subtitle2" sx={{color: theme.palette.customColor.dark}}>
                        {formulaFeature?.featureName}
                    </Typography>
                </Box>
            </Box>

            <Divider
                sx={{
                    width: "100%",
                    backgroundColor: theme.palette.customColor.lightGrey1,
                }}
            />

            <Box sx={{width: "100%"}}>
                <Typography
                    className="flex-box-align-center"
                    variant="body1"
                    sx={{
                        color: theme.palette.customColor.grey,
                        lineHeight: "20px",
                        textTransform: "capitalize",
                        marginBottom: "8px"
                    }}
                >
                    Formula SQL
                </Typography>

                 <Box className="flex-box-align-center">
                    <span
                        className="material-symbols-outlined"
                        style={{fontSize: "18px", color: theme.palette.customColor.purple, marginRight: "8px"}}
                    >
                            {featureTypeIcons.formulaSQL}
                        </span>
                        <Typography variant="subtitle2" sx={{color: theme.palette.customColor.dark}}>
                            {calculateFormulaSQL()}
                        </Typography>
                    </Box>
            </Box>

            <Box className="flex-box-col-start" sx={{width: "100%", gap: "16px"}}>
                {featuresFormula.length > 0 ? (
                    featuresFormula.map((feature, index) => (
                        <Box key={index} className="flex-box-col" sx={{width: "100%"}}>
                            <Typography
                                className="flex-box-align-center"
                                variant="body1"
                                sx={{color: theme.palette.customColor.grey, lineHeight: "20px", textTransform: "capitalize", marginBottom: "8px"}}
                            >
                                Feature {index + 1}
                            </Typography>
                            <Box
                                sx={{
                                    borderRadius: "12px",
                                    backgroundColor: theme.palette.customColor.lightestGrey6,
                                    padding: "12px",
                                }}
                            >
                                <FeatureInfo assetFeature={feature} formulaFeature={formulaFeature}/>
                            </Box>
                        </Box>
                    ))
                ) : (
                    <></>
                )}
            </Box>
        </Box>
    );
};

export default FeatureFormula;