import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "theme";
import {Chip, Tooltip} from "@mui/material";
import {FeatureType, MeasureType} from "../../../../types/features";

interface FeatureFieldProps {
    fieldName: string;
    fieldIcon: string;
    featureType: string;
    featureName: string;
    definition: string;
    featureField: string;
    featureSortBy: string;
    featureDataAsset: string;
    featureMeasure: string | MeasureType[];
    filtersData: Filter[];
    changeFiltersStyle?: boolean;
}

export interface Filter {
    icon: string;
    field: string;
    operator: string;
    value: string;
}

const FeatureField: React.FC<FeatureFieldProps> = ({
                                                       fieldName,
                                                       featureType,
                                                       featureName,
                                                       featureField,
                                                       featureSortBy,
                                                       featureDataAsset,
                                                       featureMeasure,
                                                       filtersData,
                                                       definition,
                                                   }) => {

    const dataAssetTooltip = (
        <>
            <Typography variant="h5" sx={{color: theme.palette.customColor.white, opacity: .6,}}>
                db.schema
            </Typography>
            <Typography variant="subtitle2" sx={{color: theme.palette.customColor.white}}>
                {featureDataAsset.split('.').slice(0, -1).join('.')}
            </Typography>
        </>
    );
    return (
        <>
            {(fieldName === featureType || (featureType === "first" || featureType === "last") && fieldName === "First-Last") && (
                <>
                    <Box sx={{marginTop: "-4px"}}>
                        <Typography variant="subtitle2" sx={{color: theme.palette.customColor.dark}}>
                            {featureName}
                        </Typography>
                    </Box>
                </>
            )}

            {fieldName === "Measure SQL" && (
                <Chip
                    label={Array.isArray(featureMeasure) ? featureMeasure.join(", ") : featureMeasure}
                    color="primary"
                    variant="outlined"
                    className="asset-title-chip"
                />
            )}

            {fieldName === "Field" && featureField && (
                <Chip label={featureField} color="primary" variant="outlined" className="asset-title-chip"/>
            )}

            {fieldName === "Formula SQL" && (
                <Typography variant="subtitle2" sx={{color: theme.palette.customColor.dark}}>
                    {definition.split("/").slice(0).join('+')}
                </Typography>
            )}
            {fieldName === "Sort by" && featureSortBy && (
                <Typography variant="subtitle2" sx={{color: theme.palette.customColor.dark}}>
                    {featureSortBy}
                </Typography>
            )}
            {fieldName === "Method" && featureField && (
                <Typography variant="subtitle2" sx={{color: theme.palette.customColor.dark}}>
                    {featureType}
                </Typography>
            )}
            {fieldName === "Data asset" && (
                <Tooltip
                    title={dataAssetTooltip}
                    placement="top"
                    arrow
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -10],
                                },
                            },
                        ],
                    }}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: theme.palette.customColor.dark,
                                borderRadius: "12px",
                                padding: "10px 12px",
                                '& .MuiTooltip-arrow': {
                                    color: theme.palette.customColor.dark,
                                },
                            },
                        },
                    }}
                >
                    <Typography variant="subtitle2" sx={{color: theme.palette.customColor.dark}}>
                        {featureDataAsset.split('.').pop()}
                    </Typography>
                </Tooltip>
            )}
            {fieldName === "Data type" && (
                <Typography variant="subtitle2" sx={{color: theme.palette.customColor.grey}}>
                    None
                </Typography>
            )}

            {fieldName === "Aggregation time field" && (
                <Typography variant="subtitle2" sx={{color: theme.palette.customColor.grey}}>
                    None
                </Typography>
            )}
            {fieldName === "Filters" && (
                <Box
                    className="flex-box-col"
                    sx={{gap: "20px"}}
                >
                    {filtersData.length > 0 ? (
                        filtersData.map((filter, index) => (
                            <Box
                                key={index}
                                className="flex-box-align-center"
                                sx={{
                                    position: "relative",
                                    cursor: "pointer",
                                    borderRadius: "12px",
                                    background: theme.palette.customColor.lightestGrey5,
                                    padding: "4px 6px",
                                }}
                            >
                                <Box className="flex-box-align-center">
                        <span
                            className="material-symbols-outlined"
                            style={{
                                fontSize: "18px",
                                color: theme.palette.customColor.slateGrey,
                                marginRight: "8px",
                            }}
                        >
                            {filter.icon}
                        </span>

                                    <Typography variant="h5" sx={{color: theme.palette.customColor.darkGrey}}>
                                        {filter.field}
                                    </Typography>
                                </Box>

                                <Box className="flex-box-align-center">
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: theme.palette.customColor.grey,
                                            margin: "0 6px",
                                        }}
                                    >
                                        {filter.operator}
                                    </Typography>

                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: theme.palette.customColor.darkGrey,
                                            cursor: "pointer",
                                        }}
                                    >
                                        {filter.value}
                                    </Typography>
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <Typography variant="subtitle2" sx={{color: theme.palette.customColor.grey}}>
                            None
                        </Typography>
                    )}
                </Box>
            )}
        </>
    );
};

export default FeatureField;