import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "theme";
import {FeatureRow} from "../../business-entity-component/business-entity-tabs/business-entity-tabs.component";
import "./feature-info.component.scss";
import {FeatureType} from "../../../types/features";
import {Divider} from "@mui/material";
import FeatureField, {Filter} from "./features-field/features-field.component";

interface FeatureInfoProps {
    assetFeature: FeatureRow;
    formulaFeature?: FeatureRow;
    changeFiltersStyle?: boolean;
}

const FeatureInfo: React.FC<FeatureInfoProps> = ({
                                                                                   assetFeature,
                                                                                   formulaFeature,
                                                                                   changeFiltersStyle = true,
                                                                               }) => {

    const featureTypeIcons: { [key: string]: string } = {
        metric: "analytics",
        first: "process_chart",
        last: "process_chart",
        formula: "ƒ",
        field: "linked_services",
    };

    const featureFieldIcons: { [key: string]: string } = {
        type: featureTypeIcons[assetFeature.featureType] || "",
        field: "match_case",
        sortBy: "calendar_today",
        method: "sort",
        dataAsset: "database",
        filters: "filter_alt",
        measure: "tag",
    };

    const filtersData: Filter[] = (assetFeature.featureFilters || []).map((filter) => ({
        icon: featureFieldIcons.field,
        field: filter.field,
        operator: filter.operator,
        value: Array.isArray(filter.values) ? filter.values.join(", ") : filter.values,
    }));

    const featureFields = [
        {
            name: assetFeature.featureType === "first" || assetFeature.featureType === "last" ? "First-Last" : assetFeature.featureType,
            icon: featureFieldIcons.type,
        },
        ...(assetFeature.featureType === "first" || assetFeature.featureType === "last"
            ? [{ name: "Field", icon: featureFieldIcons.field }, { name: "Sort by", icon: featureFieldIcons.sortBy }, { name: "Method", icon: featureFieldIcons.method }]
            : []),
        (assetFeature.featureType === FeatureType.metric)
            ? { name: "Measure SQL", icon: featureFieldIcons.measure }
            : null,
        (assetFeature.featureType === FeatureType.formula)
            ? { name: "Formula SQL", icon: featureFieldIcons.measure }
            : { name: "Data asset", icon: featureFieldIcons.dataAsset },
    ...(assetFeature.featureType === FeatureType.formula)
            ? []
            : [{ name: "Filters", icon: featureFieldIcons.filters }, { name: "Data type", icon: featureFieldIcons.measure }, { name: "Aggregation time field", icon: featureFieldIcons.sortBy }]
    ].filter((field): field is { name: string; icon: string } => Boolean(field));

    return (
        <Box className="flex-box-col-start" sx={{width: changeFiltersStyle ? "auto" : "100%", gap: "16px"}}>
            {featureFields.map((field, index) => (
                <Box sx={{width: "100%"}}>
                    <Box key={index}
                         className=""
                         sx={{width: "100%"}}>

                            <Box
                                className="flex-box-col-start"
                                sx={{
                                }}
                            >
                                <Typography
                                    className="flex-box-align-center"
                                    variant="body1"
                                    sx={{color: theme.palette.customColor.grey, lineHeight: "20px", textTransform: "capitalize", marginBottom: "8px"}}
                                >
                                    {field.name}
                                </Typography>

                                <Box className={field.name === assetFeature.featureType ? "flex-box-align-start" : "flex-box-align-center"}>
                                    <span
                                        className="material-symbols-outlined"
                                        style={{
                                            fontSize: "18px",
                                            color: field.name === "Filters" || field.name === "Data type" || field.name === "Aggregation time field"
                                                ? (filtersData.length > 0
                                                    ? theme.palette.customColor.purple
                                                    : theme.palette.customColor.slateGrey)
                                                : theme.palette.customColor.purple,
                                            marginRight: "8px",
                                        }}
                                    >
                                        {field.icon}
                                    </span>
                                    <FeatureField
                                        fieldName={field.name}
                                        fieldIcon={field.icon}
                                        featureType={assetFeature.featureType}
                                        featureName={assetFeature.featureName}
                                        definition={assetFeature.definition}
                                        featureField={assetFeature.featureField || ""}
                                        featureSortBy={assetFeature.featureSortBy || ""}
                                        featureDataAsset={assetFeature.featureDataAsset || ""}
                                        featureMeasure={assetFeature.featureMeasure || ""}
                                        filtersData={filtersData}
                                        changeFiltersStyle={changeFiltersStyle}
                                    />
                                </Box>
                                {index !== featureFields.length - 1 && formulaFeature?.featureType !== FeatureType.formula && (
                                    <Divider
                                        sx={{
                                            width: "100%",
                                            backgroundColor: theme.palette.customColor.lightGrey1,
                                            marginTop: "16px",
                                        }}
                                    />
                                )}
                            </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default FeatureInfo;