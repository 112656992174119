import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import BusinessEntityTabsTable
, { BusinessEntityTabsTableRef }    from "./business-entity-tabs-table/business-entity-tabs-table.component";
import {EntityDetailResponse} from "types/index";
import {GridCellParams} from "@mui/x-data-grid";
import {Chip, Typography} from "@mui/material";
import theme from "theme";
import {Link} from "react-router-dom";
import BusinessEntityFeaturesModal from "../business-entity-features-modal/business-entities-features-modal.component";
import {useEffect, useRef, useState} from "react";
import {DataAssetColumn, DataAssetFullResponse} from "services/data-assets";
import {
    DerivedFeature, AssetFeature, FieldFeature, FirstLastFeature,
    MetricFeature,
    FeatureFilterApi,
    FeatureType,
    MeasureType,
} from "types/features";
import AssetTitle from "../../shared/assets/asset-title/asset-title.component";
import FeatureTypeDisplay from "../../add-features-component/feature-type-display";
import {useGlobalContext} from "context/global-context";
import LoadingSpinner from "components/shared/LoadingSpinner";
import {getRelationshipLabelByValue} from "constants/entity-relationships";
import BusinessEntityDrawer from "../business-entity-drawer/business-entity-drawer-component";
import RelationDisplay from "components/add-edit-business-entity-component/related-join/relation-display.component";
import { useCacheContext } from "context/cache-context";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export interface Props {
    entity: EntityDetailResponse | null,
    keyTableFields: DataAssetColumn[],
    onDataRefresh: () => void,
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{py: 3}}>{children}</Box>}
        </div>
    );
}

function getTabProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export enum EntityTabs {
    Relationships = "Relationships",
    DataAssets = "DataAssets",
    Features = "Features"
}

interface AssetsCache {
    [key: string]: Promise<DataAssetFullResponse>
}

export type FeatureRow = {
    id: string;
    featureName: string;
    featureDataAsset: string;
    featureType: string;
    definition: string;
    featureSortBy?: string;
    featureField?: string;
    featureMeasure?: string | MeasureType[];
    featureFilters?: FeatureFilterApi[]
    featureDerivedName?: string[];
    featureDerivedAlias?: string;
};

const BusinessEntityTabs: React.FC<Props> = ({entity, keyTableFields, onDataRefresh}) => {
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [features, setFeatures] = React.useState<FeatureRow[]>([]);
    const [isFeaturesModalOpen, setIsFeaturesModalOpen] = useState<boolean>(false);
    const {selectedGitBranch, isEditMode} = useGlobalContext();
    const [selectedFeature, setSelectedFeature] = useState<FeatureRow | null>(null);
    const { getAndCacheAssetById } = useCacheContext()
    const handleDrawerOpen = (feature: FeatureRow) => {
        setSelectedFeature(feature);
    };
    const tabTableRef = useRef<BusinessEntityTabsTableRef>(null)


    const handleDrawerClose = () => {
        setSelectedFeature(null);
    };

    const handleChange = (event: React.SyntheticEvent, newTabIndex: number) => {
        setSelectedTabIndex(newTabIndex);
    };

    const handleToggleAddFeatureModalOpen = (isOpen: boolean, shouldRefresh?: boolean) => {
        setIsFeaturesModalOpen(isOpen);
        if (shouldRefresh)
            onDataRefresh();
    };

    useEffect(() => {
        tabTableRef.current?.clearFilter()
        setIsLoading(true)
        const fetchFeatures = async () => {
            if (entity) {
                const useFeatures = await getFeatures();

                useFeatures.push(...keyTableFields.map(f => ({
                    id: `key-table-${f.name}`,
                    featureName: f.name,
                    featureDataAsset: entity.keyTable,
                    featureType: FeatureType.field,
                    definition: f.name
                })));

                setFeatures(useFeatures);
                setIsLoading(false)
            }
        }

        fetchFeatures()
    }, [entity, keyTableFields]);

    const getFeatures = async (): Promise<FeatureRow[]> => {
        const features: FeatureRow[] = [];
        
        if (!entity) {
            return []
        }

        for (let featureIndex = 0; featureIndex < entity.features.length; featureIndex++) {
            let feature = entity.features[featureIndex];

            if (feature.type === FeatureType.formula) {
                feature = feature as DerivedFeature;
                const derivedFeatureNames: string[] = (feature.features || []).map(f => f.feature);
                features.push({
                    id: `derived-${feature.name}`,
                    featureName: feature.name,
                    featureType: FeatureType.formula,
                    featureDataAsset: "",
                    definition: feature.sql,
                    featureDerivedName: derivedFeatureNames,
                });
                continue;
            }

            let featureType: string = feature.type;
            let definition: string = "";
            let featureField: string | undefined;
            let featureSortBy: string | undefined;
            let featureMeasure: string | undefined;

            if (feature.type === FeatureType.firstLast) {
                feature = feature as FirstLastFeature;
                if ("options" in feature) {
                    featureType = feature.options.method;
                    featureField = feature.options.field;
                    featureSortBy = feature.options.sortBy;
                    definition = `${feature.options.field} by ${feature.options.sortBy}`;
                }
            } else if (feature.type === FeatureType.field) {
                feature = feature as FieldFeature;
                definition = feature.name;
            } else if (feature.type === FeatureType.metric) {
                feature = feature as MetricFeature;
                const asset: DataAssetFullResponse = await getAndCacheAssetById(feature.asset, isEditMode ? selectedGitBranch : null);
                const measureData = asset.measures.find(
                    m => m.name === (feature as MetricFeature).measure);
                if (measureData?.sql) {
                    featureMeasure = measureData.sql;
                    definition = measureData.sql;
                }
            }

            feature = feature as AssetFeature;
            const featureFilters = feature.filters
                ? feature.filters.map(filter => ({
                    field: filter.field,
                    operator: filter.operator,
                    values: filter.values,
                    type: filter.type
                }))
                : [];

            features.push({
                id: `asset-${feature.name}`,
                featureName: feature.name,
                featureType: featureType,
                featureDataAsset: feature.asset,
                definition,
                featureSortBy,
                featureField,
                featureMeasure,
                featureFilters: featureFilters,
            });
        }

        return features;
    };

    const relatedAssets = Object.keys(entity?.relatedAssets || {}).map((assetId, index) => {
        const [db, schema, tableName] = assetId.split(".")
        return {
            id: index,
            columns: entity?.relatedAssets[assetId],
            tableId: assetId,
            rawTableName: tableName || assetId
        }
    }).sort((a, b) => a.rawTableName.localeCompare(b.rawTableName))

    return (
        <Box sx={{width: "100%"}}>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <Tabs
                    value={selectedTabIndex}
                    onChange={handleChange}
                    aria-label="Business entities"
                    sx={{
                        "& .MuiTab-root": {
                            minWidth: "auto",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "24px",
                            marginRight: "32px",
                            padding: "16px 0",
                        },
                        "& .MuiTabs-indicator": {
                            backgroundColor: theme.palette.customColor.purple,
                        },
                        "& .Mui-selected": {
                            color: `${theme.palette.customColor.purple} !important`,
                        },
                    }}
                >
                    <Tab label="Features" {...getTabProps(1)} />
                    <Tab label="Relationships" {...getTabProps(1)} />
                    <Tab label="Data assets" {...getTabProps(2)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={selectedTabIndex} index={1}>
                <BusinessEntityTabsTable
                    ref={tabTableRef}
                    name={EntityTabs.Relationships}
                    data={Object.entries(entity?.relatedEntities || {}).map(([entityId, relatedEntity], index) => ({
                        id: index,
                        entity: entityId,
                        relationship: getRelationshipLabelByValue(relatedEntity.relationship as string),
                    }))}
                    columns={[
                        {
                            field: "entity",
                            headerName: "Entity",
                            flex: 1,
                            sortable: true,
                            headerClassName: "entity-columns--header"
                        },
                        {
                            field: "relationship",
                            headerName: "Relationship",
                            flex: 1,
                            sortable: false,
                            headerClassName: "entity-columns--header"
                        },
                    ]}
                />
            </CustomTabPanel>
            <CustomTabPanel value={selectedTabIndex} index={0}>
                <Box sx={{position: "relative"}}>
                    <BusinessEntityTabsTable
                        ref={tabTableRef}
                        name={EntityTabs.Features}
                        data={features}
                        columns={[
                            {
                                field: "featureName",
                                headerName: "Feature name",
                                flex: 1,
                                sortable: true,
                                headerClassName: "entity-columns--header",
                                renderCell: (params: GridCellParams) => (
                                    params.value ? (
                                            <Typography
                                                className="data-asset-cell-text"
                                                variant="subtitle2"
                                                onClick={() => {
                                                    handleDrawerOpen(params.row);
                                                }}
                                                sx={{color: theme.palette.customColor.dark, cursor: "pointer"}}
                                            >
                                                {String(params.value)}
                                            </Typography>
                                    ) : null
                                ),
                            },
                            {
                                field: "featureType",
                                headerName: "Type",
                                flex: 1,
                                sortable: true,
                                headerClassName: "entity-columns--header",
                                renderCell: (params: GridCellParams) => (
                                    <FeatureTypeDisplay featureType={String(params.value)}/>
                                ),
                            },
                            {
                                field: "definition",
                                headerName: "Definition",
                                flex: 1,
                                sortable: true,
                                headerClassName: "entity-columns--header",
                                renderCell: (params: GridCellParams) => (
                                    params.value ? (
                                        <Chip
                                            label={String(params.value)}
                                            color="primary"
                                            variant="outlined"
                                            className="tag-cell"
                                        />
                                    ) : null
                                ),
                            },
                            {
                                field: "featureDataAsset",
                                headerName: "Data asset",
                                flex: 1,
                                sortable: false,
                                headerClassName: "entity-columns--header",
                                renderCell: (params: GridCellParams) => (
                                    <Typography
                                        className="data-asset-cell-text"
                                        variant="h5"
                                    >
                                        {String(params.value)}
                                    </Typography>
                                ),
                            }
                        ]}
                    />
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={selectedTabIndex} index={2}>
                <BusinessEntityTabsTable
                    ref={tabTableRef}
                    name={EntityTabs.DataAssets}
                    data={relatedAssets}
                    columns={[
                        {
                            field: "tableId",
                            headerName: "Data asset name",
                            flex: 1,
                            sortable: true,
                            headerClassName: "entity-columns--header",
                            renderCell: (params: GridCellParams) => (
                                <Link to={`/data-assets/${params.value}`}>
                                    <AssetTitle assetId={String(params.value)}/>
                                </Link>
                            ),
                        },
                        {
                            field: "relation",
                            headerName: "Relation",
                            flex: 1,
                            sortable: false,
                            headerClassName: "entity-columns--header",
                            renderCell: (params: GridCellParams) => {
                                return (
                                    <RelationDisplay relation={params.row.columns}/>
                                );
                            }
                        },
                    ]}
                />
            </CustomTabPanel>
            <LoadingSpinner open={isLoading}/>
            {entity && (<BusinessEntityFeaturesModal entity={entity} isModalOpen={isFeaturesModalOpen}
                                                     onClose={(shouldRefresh) => handleToggleAddFeatureModalOpen(false, shouldRefresh)}/>)}

            <BusinessEntityDrawer
                onClose={handleDrawerClose}
                features={features}
                selectedFeature={selectedFeature}
            />
        </Box>
    );
};

export default BusinessEntityTabs;
